import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppContent } from './content.service';
import {Customer} from "./customer.service";

export type Slot = {
  id: number;
  title: string;
  enabled: boolean;
  public_hash: string;
  content_id?: number;
  customer_id: number;
  created_at: string;
  updated_at: string;
  content_data?: AppContent;
};

@Injectable({
  providedIn: 'root',
})
export class SlotService {
  constructor(private http: HttpClient) {}

  getSlots(params: any) {
    let updatedParams: any = {};
    // remove undefined key&value pairs from query
    Object.entries(params)
      .filter(([k, v]) => v !== undefined)
      .forEach(([k, v]) => {
        updatedParams[k] = v;
      });

    return this.http.get<{ success: boolean; total: number; data: Slot[] }>(
      '/api/slots/',
      {
        params: updatedParams,
      }
    );
  }

  updateSlot(id: number, data: any) {
    return this.http.put<{ success: boolean; data: Customer }>(
      `/api/slots/${id}`,
      data
    );
  }
}
