import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Slot, SlotService } from '../../backend-services/slot.service';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalEvents } from '../../shared-components/custom-modal/custom-modal.component';
import { SlotEditModalService } from '../slot-edit-modal/slot-edit-modal.service';

@Component({
  selector: 'app-slots-listview-page',
  templateUrl: './slots-listview-page.component.html',
  styleUrls: ['./slots-listview-page.component.scss'],
})
export class SlotsListviewPageComponent implements OnInit {
  queryParams: any = {};
  searchControl = new FormControl();
  totalCount = 0;
  slots: Slot[] = [];
  slotToPreview?: Slot;

  previewModalEvents = new Subject<ModalEvents>();

  constructor(
    public translocoService: TranslocoService,
    private slotService: SlotService,
    private router: Router,
    private route: ActivatedRoute,
    private slotEditModalService: SlotEditModalService
  ) {
    // listen to changes in the search control
    this.searchControl.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        if (value) {
          this.queryParams.search = value;
        } else {
          this.queryParams.search = undefined;
        }

        this.handleQueryParamChange();
      });

    this.previewModalEvents.subscribe((ev) => {
      switch (ev.type) {
        case 'init-close':
          this.previewModalEvents.next({ type: 'close' });
          break;
        case 'close':
          this.slotToPreview = undefined;
      }
    });

    this.slotEditModalService.modalEvents.subscribe((ev) => {
      switch (ev.type) {
        case 'close':
          this.handleRequestFetch();
      }
    });
  }

  handleQueryParamChange() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.queryParams,
      queryParamsHandling: 'merge',
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.route.queryParamMap.subscribe((params) => {
        let queryParams: Record<string, string | null> = {};
        params.keys.forEach((k) => {
          queryParams[k] = params.get(k);
        });

        this.queryParams = queryParams;

        this.handleRequestFetch();
      });
    });
  }

  handleRequestFetch() {
    this.slotService.getSlots(this.queryParams).subscribe((c) => {
      this.slots = c.data;
      this.totalCount = c.total;
    });
  }

  openPreview(slot: Slot) {
    this.slotToPreview = slot;
    this.previewModalEvents.next({ type: 'open' });
  }

  openEditModal(slot: Slot) {
    this.slotEditModalService.modalEvents.next({
      type: 'init-open',
      slot,
    });
  }

  toggleActiveState(slot: Slot) {
    this.slotService
      .updateSlot(slot.id, { ...slot, enabled: !slot.enabled })
      .subscribe(() => {
        this.handleRequestFetch();
      });
  }
}
