<div class="container mx-auto flex justify-center" *transloco="let t">
  <div class="max-w-[650px]">
    <button
      (click)="goBack()"
      class="flex items-center gap-2 font-medium text-sm text-gray7"
    >
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.8333 14.6667L9.16666 11L12.8333 7.33337"
          stroke="#828B9C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      {{ t("Zurück") }}
    </button>
    <div class="py-5">
      <h1 transloco="Profil" class="text-28px font-medium leading-9">Profil</h1>
    </div>
    <div
      class="grid grid-cols-1 lg:grid-cols-4 lg:gap-16 pt-8 pb-16 border-b border-t border-gray15 gap-y-8"
    >
      <div class="min-w-[150px]">
        <h2
          transloco="Persönliche Daten"
          class="text-lg font-medium leading-27.2px capitalize"
        >
          Persönliche Daten
        </h2>
      </div>
      <div class="lg:col-span-3">
        <div class="w-[88px] h-[88px]">
          <app-profile-picture
            [firstName]="firstNameControl.value"
            [lastName]="lastNameControl.value"
          ></app-profile-picture>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
          <app-form-input
            [label]="t('Firstname')"
            [placeholder]="t('Firstname') + '...'"
            [fc]="firstNameControl"
            [hasError]="firstNameControl.invalid && firstNameControl.touched"
            [errorMessage]=""
          ></app-form-input>
          <app-form-input
            [label]="t('Lastname')"
            [placeholder]="t('Lastname') + '...'"
            [fc]="lastNameControl"
            [hasError]="lastNameControl.invalid && lastNameControl.touched"
            [errorMessage]=""
          ></app-form-input>
          <div class="lg:col-span-2">
            <app-form-input
              [label]="t('E-Mail-Adresse')"
              [placeholder]="t('E-Mail-Adresse...')"
              [fc]="emailControl"
              [hasError]="emailControl.invalid && emailControl.touched"
              [errorMessage]="t('Ungültige E-Mail')"
            ></app-form-input>
          </div>
        </div>
      </div>
    </div>
    <div
      class="grid grid-cols-1 lg:grid-cols-4 lg:gap-16 pt-8 pb-16 border-b border-gray15 gap-y-8"
    >
      <div class="min-w-[150px]">
        <h2
          transloco="Passwort"
          class="text-lg font-medium leading-27.2px capitalize"
        >
          Passwort
        </h2>
      </div>
      <div class="lg:col-span-3">
        <div class="flex gap-2 items-center">
          <div transloco="Letzte Änderung:" class="text-gray16 text-sm">
            Letzte Änderung:
          </div>
          <div class="font-bold">
            {{ lastUpdatedAt | date: "dd.MM.yyyy" }}
          </div>
        </div>
        <button
          transloco="Passwort ändern"
          class="text-orange1 mt-4 text-sm"
          (click)="openPasswordChangeModal()"
        >
          Passwort ändern
        </button>
      </div>
    </div>
    <div class="flex justify-end gap-4 mt-6">
      <button
        transloco="Abbrechen"
        class="tw-btn-secondary-outline min-w-[140px]"
        (click)="goBack()"
      >
        Abbrechen
      </button>
      <button
        transloco="Speichern"
        class="tw-btn-secondary min-w-[140px]"
        (click)="saveProfileData()"
      >
        Speichern
      </button>
    </div>
  </div>
</div>
<app-change-password-modal
  [modalEvents]="modalEvents"
  mode="profile"
></app-change-password-modal>
