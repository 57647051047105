<nav class="z-20 w-full bg-white container mx-auto">
  <div
    class="flex items-center mx-auto p-3 2xl:p-4 justify-between lg:justify-start flex-wrap lg:flex-nowrap gap-x-12"
  >
    <a href="#" class="flex items-center space-x-3 min-h-[60px]">
      <img src="assets/imgs/school-logo.svg" alt="Logo" />
    </a>
    <button
      data-collapse-toggle="navbar-solid-bg"
      type="button"
      class="inline-flex items-center p-2 w-10 h-10 justify-center text-xs 2xl:text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      aria-controls="navbar-solid-bg"
      aria-expanded="false"
    >
      <span class="sr-only" transloco="Open main menu">Open main menu</span>
      <svg
        class="w-5 h-5"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 17 14"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M1 1h15M1 7h15M1 13h15"
        />
      </svg>
    </button>
    <div class="hidden w-full lg:block" id="navbar-solid-bg">
      <div
        class="flex flex-col lg:flex-row justify-between items-center w-full"
      >
        <ul
          class="flex flex-col font-medium mt-4 rounded-lg bg-gray-50 lg:space-x-4 lg:flex-row lg:justify-center lg:mt-0 lg:border-0 lg:bg-transparent w-full"
        >
          <li *isNotSuperAdmin>
            <a
              routerLink="/slots"
              [ngClass]="{
                'text-orange1': isActive('/slots'),
                'text-gray1 ': !isActive('/slots')
              }"
              class="flex px-4 py-2 rounded-lg gap-2 text-gray1 font-bold"
              ><span> </span>
              <span transloco="Slots">Slots</span>
            </a>
          </li>
          <ng-container *isNotSuperAdmin>
            <li>
              <a
                routerLink="/contents"
                [ngClass]="{
                  'text-orange1': isActive('/contents'),
                  'text-gray1 ': !isActive('/contents')
                }"
                class="flex px-4 py-2 rounded-lg gap-2 text-gray1 font-bold"
              >
                <span> </span>
                <span transloco="Inhalte">Inhalte</span>
              </a>
            </li>
          </ng-container>
          <li *hasCapability="'MANAGE_USERS'">
            <a
              routerLink="/user-management"
              [ngClass]="{
                'text-orange1': isActive('/user-management'),
                'text-gray1 ': !isActive('/user-management')
              }"
              class="flex px-4 py-2 rounded-lg gap-2 text-gray1 font-bold"
            >
              <span> </span>
              <span transloco="Benutzerverwaltung">Benutzerverwaltung</span>
            </a>
          </li>
        </ul>
        <ul
          class="flex font-medium rounded-lg bg-gray-50 lg:space-x-2 flex-row mt-0 lg:border-0 lg:bg-transparent items-center w-full lg:w-fit px-4 lg:px-0 gap-6 lg:gap-4"
        >
          <li class="h-[24px]">
            <button title="notifications">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.41406 14.5858L4.80357 14.1963C4.92924 14.0706 5 13.9002 5 13.7224V10C5 6.13401 8.13401 3.00001 12 3C15.866 2.99999 19 6.134 19 10V13.7224C19 13.9001 19.0706 14.0706 19.1963 14.1963L19.5858 14.5858C19.7051 14.7051 19.7649 14.7648 19.8124 14.831C19.9023 14.9561 19.9619 15.1003 19.9868 15.2523C20 15.3328 20.0002 15.4171 20.0002 15.5858C20.0002 15.9714 20.0002 16.1642 19.9478 16.3197C19.848 16.6155 19.6156 16.8477 19.3198 16.9475C19.1643 17 18.9712 17 18.5856 17H5.41406C5.02852 17 4.83568 17 4.68018 16.9475C4.38431 16.8477 4.15225 16.6155 4.05245 16.3196C4 16.1641 4 15.9714 4 15.5858C4 15.4171 4 15.3328 4.0132 15.2523C4.03815 15.1003 4.09766 14.9561 4.1875 14.831C4.23504 14.7648 4.29476 14.7051 4.41406 14.5858ZM15 17V18C15 19.6569 13.6569 21 12 21C10.3431 21 9 19.6569 9 18V17H15Z"
                  stroke="#667085"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </li>
          <li>
            <button
              type="button"
              data-drawer-target="side-drawer"
              data-drawer-show="side-drawer"
              data-drawer-placement="right"
              aria-controls="side-drawer"
              class="w-10 h-10"
            >
              <app-profile-picture
                [firstName]="authService.profile?.value?.first_name"
                [lastName]="authService.profile?.value?.last_name"
              ></app-profile-picture>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
