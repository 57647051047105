import { Component, OnInit } from '@angular/core';
import { SlotEditModalService } from './slot-edit-modal.service';
import {
  AppContent,
  ContentService,
} from '../../backend-services/content.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-slot-edit-modal',
  templateUrl: './slot-edit-modal.component.html',
  styleUrls: ['./slot-edit-modal.component.scss'],
})
export class SlotEditModalComponent implements OnInit {
  availableContents: AppContent[] = [];

  constructor(
    public slotEditModalService: SlotEditModalService,
    private contentService: ContentService,
    private translocoService: TranslocoService
  ) {
    this.contentService.getAllContents().subscribe((res) => {
      this.availableContents = res.data;
    });
  }

  ngOnInit(): void {}

  getContentNameById(id?: number) {
    let content;
    if (id) {
      content = this.availableContents.find((c) => c.id === id);
    }

    return content?.title || this.translocoService.translate('Fallback');
  }

  get selectedContent() {
    const id = this.slotEditModalService.contentIdControl.value;
    if (!id)
      return undefined;
    return this.availableContents.find((c) => c.id === id)

  }
}
