<button
  [attr.data-modal-target]="componentId"
  [attr.data-modal-toggle]="componentId"
  class="hidden"
  #toggleCustomModalBtn
></button>
<button
  [attr.data-modal-target]="componentId"
  [attr.data-modal-show]="componentId"
  class="hidden"
  #showCustomModalBtn
></button>
<button
  [attr.data-modal-target]="componentId"
  [attr.data-modal-hide]="componentId"
  class="hidden"
  #hideCustomModalBtn
></button>

<div
  [id]="componentId"
  tabindex="-1"
  aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)]"
>
  <ng-container *transloco="let t">
    <div
      class="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-black/10 z-50"
    >
      <div class="relative z-50 p-3 2xl:p-4 w-full" [ngClass]="classes || ''">
        <!-- Modal content -->
        <div class="relative bg-white rounded-3xl shadow overflow-hidden">
          <!-- Modal header -->
          <div class="flex items-center justify-between pt-4 px-4 rounded-t">
            <button
              type="button"
              class="text-primary bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xs 2xl:text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              (click)="initClose()"
            >
              <svg
                class="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span class="sr-only" transloco="Close modal">Close modal</span>
            </button>
          </div>
          <div class="px-4 flex flex-col">
            <ng-content select="[header]"></ng-content>
          </div>
          <div
            class="p-4 space-y-4 flex flex-col overflow-y-auto"
            [ngClass]="{
              customMaxHeightClass: customMaxHeightClass,
              'max-h-[600px]': !customMaxHeightClass
            }"
          >
            <ng-content select="[content-body]"></ng-content>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
