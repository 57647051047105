<app-custom-modal
  classes="max-w-[644px]"
  [modalEvents]="contentPreviewModalService.modalEvents"
  customMaxHeightClass="max-h-[940px]"
  *transloco="let t"
>
  <div header class="flex flex-col gap-2 px-9">
    <h2 class="text-black font-medium text-28px" transloco="Vorschau">
      Vorschau
    </h2>
    <h3 class="text-base text-black">
      {{ contentPreviewModalService.currentContent?.title }}
    </h3>
  </div>
  <div content-body class="flex flex-col items-center px-9 mt-6 overflow-auto">
    <div class="relative w-fit">
      <img src="/assets/imgs/tv_frame.png" alt="" />
      <img
        [src]="contentPreviewModalService.currentContent?.stored_file?.url"
        class="absolute left-[27px] top-[7px] w-[374px] h-[658px]"
        alt="preview"
      />
    </div></div
></app-custom-modal>
