import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Location } from '@angular/common';
import { CustomerService } from '../../backend-services/customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import {
  AppContent,
  ContentService,
} from '../../backend-services/content.service';
import { v7 as uuidv7 } from 'uuid';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-customer',
  templateUrl: './manage-single-customer.component.html',
  styleUrls: ['./manage-single-customer.component.scss'],
})
export class ManageSingleCustomerComponent {
  @ViewChild('confirmBackModalTrigger') confirmBackModalTrigger:
    | ElementRef
    | undefined;

  availableContents: AppContent[] = [];

  customerForm = new FormGroup({
    company: new FormControl('', [Validators.required]),
    billing_street: new FormControl('', [Validators.required]),
    billing_house_number: new FormControl('', [Validators.required]),
    billing_zip: new FormControl('', [Validators.required]),
    billing_city: new FormControl('', [Validators.required]),
    billing_email: new FormControl('', [Validators.email, Validators.required]),
    billing_phone: new FormControl('', [
      Validators.required,
      Validators.required,
    ]),
    invoice_by_mail: new FormControl(true, []),
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    enabled: new FormControl(true, []),
    slots: new FormArray([this.buildNewSlotsGroup()]),
  });
  customerFormLoading = false;

  id: number | null = null;

  initialData: any = undefined;

  constructor(
    private _location: Location,
    private customerService: CustomerService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translocoService: TranslocoService,
    private contentsService: ContentService,
    private clipboard: Clipboard
  ) {
    this.initialData = this.customerForm.value;

    this.route.paramMap.subscribe({
      next: (val) => {
        const id = val.get('id');
        if (id) {
          this.id = parseInt(id);
          this.customerFormLoading = true;
          this.customerForm.disable();

          this.customerService.getCustomerById(this.id).subscribe({
            next: (res) => {
              if (res) {
                this.customerFormLoading = false;

                // set form values
                this.companyControl.setValue(res.company);
                this.firstNameControl.setValue(res.first_name);
                this.lastNameControl.setValue(res.last_name);
                this.enabledControl.setValue(res.enabled);
                this.billingStreetControl.setValue(res.billing_street);
                this.billingHouseNumberControl.setValue(
                  res.billing_house_number
                );
                this.billingZipControl.setValue(res.billing_zip);
                this.billingCityControl.setValue(res.billing_city);
                this.billingEmailControl.setValue(res.billing_email);
                this.phoneControl.setValue(res.phone);
                this.billingPhoneControl.setValue(res.billing_phone);

                this.customerForm
                  .get('invoice_by_mail')!
                  .setValue(res.invoice_by_mail);

                this.slotArray.clear();
                // update slots
                res.slots!.forEach((slot) =>
                  this.slotArray.push(this.buildNewSlotsGroup(slot))
                );

                this.customerForm.enable();

                this.initialData = this.customerForm.value;
              }
            },
            error: (res) => {
              console.log('err', res.error);
              this.toastr.error(
                this.translocoService.translate(`User Not Found`)
              );
            },
          });
        }
      },
    });

    this.contentsService.getAllContents().subscribe((res) => {
      this.availableContents = res.data.filter((i) => i.customer_id && i.customer_id === this.id);
    });
  }

  get companyControl() {
    return this.customerForm.get('company') as AbstractControl;
  }

  get firstNameControl() {
    return this.customerForm.get('first_name') as AbstractControl;
  }

  get lastNameControl() {
    return this.customerForm.get('last_name') as AbstractControl;
  }

  get billingStreetControl() {
    return this.customerForm.get('billing_street') as AbstractControl;
  }

  get billingHouseNumberControl() {
    return this.customerForm.get('billing_house_number') as AbstractControl;
  }

  get billingZipControl() {
    return this.customerForm.get('billing_zip') as AbstractControl;
  }

  get billingCityControl() {
    return this.customerForm.get('billing_city') as AbstractControl;
  }

  get billingEmailControl() {
    return this.customerForm.get('billing_email') as AbstractControl;
  }

  get phoneControl() {
    return this.customerForm.get('phone') as AbstractControl;
  }

  get billingPhoneControl() {
    return this.customerForm.get('billing_phone') as AbstractControl;
  }

  get enabledControl() {
    return this.customerForm.get('enabled') as FormControl;
  }

  get slotArray() {
    return this.customerForm.get('slots') as FormArray;
  }

  getContentNameById(id?: number) {
    let content;
    if (id) {
      content = this.availableContents.find((c) => c.id === id);
    }

    return content?.title || this.translocoService.translate('Fallback');
  }

  buildNewSlotsGroup(data?: any) {
    return new FormGroup({
      id: new FormControl(data ? data.id : null),
      enabled: new FormControl(data ? data.enabled : true),
      content_id: new FormControl(data ? data.content_id : null),
      public_hash: new FormControl(data ? data.public_hash : uuidv7()),
    });
  }

  addNewSlot() {
    this.slotArray.push(this.buildNewSlotsGroup());
  }

  copySlotUrl(hash: string) {
    const url = `${window.origin}/api/slots/public/${hash}`;
    this.clipboard.copy(url);
    this.toastr.info(this.translocoService.translate(`Link copied`));
  }

  handleSubmit() {
    this.customerForm.markAllAsTouched();

    if (this.customerForm.valid) {
      this.customerFormLoading = true;

      if (!this.id) {
        this.customerService.createCustomer(this.customerForm.value).subscribe({
          next: (res) => {
            this.customerFormLoading = false;
            if (res.success) {
              this.toastr.success(
                this.translocoService.translate(
                  `Neuer Schule erfolgreich erstellt!`
                )
              );
              this.router.navigate([`/user-management/`], {
                replaceUrl: true,
                queryParams: {
                  management_type: 'customers',
                },
              });
            }
          },
          error: (res) => {
            console.log('err', res.error);
            this.toastr.error(
              res.error?.error ||
                this.translocoService.translate(`Something went wrong`)
            );
            this.customerFormLoading = false;
          },
        });
      } else {
        this.customerService
          .updateCustomer(this.id, this.customerForm.value)
          .subscribe({
            next: (res) => {
              this.customerFormLoading = false;
              if (res.success) {
                this.customerForm.markAsUntouched();
                this.toastr.success(
                  this.translocoService.translate(
                    `Ihr Schule wurde gespeichert`
                  )
                );

                this.router.navigate([`/user-management/`], {
                  replaceUrl: true,
                  queryParams: {
                    management_type: 'customers',
                  },
                });
              }
            },
            error: (res) => {
              console.log('err', res.error);
              this.toastr.error(
                res.error?.error ||
                  this.translocoService.translate(`Something went wrong`)
              );
              this.customerFormLoading = false;
            },
          });
      }
    }
  }

  goBackCheck() {
    if (
      JSON.stringify(this.initialData) !==
      JSON.stringify(this.customerForm.value)
    ) {
      this.confirmBackModalTrigger?.nativeElement?.click();
    } else {
      this.goBack();
    }
  }

  goBack() {
    this._location.back();
  }
}
