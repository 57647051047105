import { Component, OnInit } from '@angular/core';
import { ContentPreviewModalService } from './content-preview-modal.service';

@Component({
  selector: 'app-content-preview-modal',
  templateUrl: './content-preview-modal.component.html',
  styleUrls: ['./content-preview-modal.component.scss'],
})
export class ContentPreviewModalComponent implements OnInit {
  constructor(public contentPreviewModalService: ContentPreviewModalService) {}

  ngOnInit(): void {}
}
